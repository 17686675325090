<template>
  <div>
    <el-dialog
      :title="rowItem.id ? '编辑' : '新增'"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose">
      <div style="padding-right: 20px">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="等级名称" prop="levelName">
            <el-input class="w200" v-model="ruleForm.levelName" maxlength="30" placeholder="请输入等级名称" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="年龄" prop="age">
            <el-checkbox-group :disabled="rowItem.id" v-model="ruleForm.age">
              <el-checkbox label="60-69" name="type">60-69岁</el-checkbox>
              <el-checkbox label="70-79" name="type">70-79岁</el-checkbox>
              <el-checkbox label="80-89" name="type">80-89岁</el-checkbox>
              <el-checkbox label="90-99" name="type">90-99岁</el-checkbox>
              <el-checkbox label="100" name="type">100岁以上</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="身份类别" prop="type">
            <el-radio-group :disabled="rowItem.id"  v-model="ruleForm.type">
              <el-radio label="0">普通老年人</el-radio>
              <el-radio label="1">低保老年人</el-radio>
              <el-radio label="2">失能老年人</el-radio>
              <el-radio label="3">低保失能老年人</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="月标准" prop="subsidyStandard">
            <el-input-number class="yuan" v-model="ruleForm.subsidyStandard" :min="0" :max="999999999">

            </el-input-number>
          </el-form-item>
          <el-form-item v-if="rowItem.id" label="生效时间" prop="nextFlag">
            <el-select v-model="ruleForm.nextFlag"  placeholder="请选择生效时间">
            <el-option label="当月" :value="0"></el-option>
            <el-option label="次月" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
  import { addSubsidyLevel, updateSubsidyLevel } from "@/api/highCollarSubsidy"
  export default {
    name: "addOrEditLevel",
    props: {
      dialogVisible: {
        type: Boolean,
        default: false
      },
      rowItem: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        ruleForm: {
          levelName: '',
          age: [],
          type: '0',
          subsidyStandard: 0,
          nextFlag: ''
        },
        rules: {
          levelName: [
            { required: true, message: '请输入等级名称', trigger: 'blur' },
          ],
          age: [
            { required: true, message: '请选择年龄', trigger: 'change' },
          ],
          type: [
            { required: true, message: '请选择身份类别', trigger: 'change' },
          ],
          subsidyStandard: [
            { required: true, message: '请输入月标准', trigger: 'change' },
          ],
          nextFlag: [
            { required: true, message: '请输入请选择生效时间', trigger: 'change' },
          ],
        }
      }
    },
    created() {
      let { levelName ,age, type, subsidyStandard, id, nextFlag } = this.rowItem
        this.ruleForm.levelName = levelName
        this.ruleForm.age = id ?  age.split(',') : age
        this.ruleForm.type = type
        this.ruleForm.nextFlag = nextFlag
        this.ruleForm.subsidyStandard = subsidyStandard
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(!this.rowItem.id) {
              this.addSubsidyLevelPost()
            }else {
              this.updateSubsidyLevelPost()
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      /*新增*/
      async addSubsidyLevelPost() {
        const params = JSON.parse(JSON.stringify(this.ruleForm))
        params.age = params.age.join(',')
        const res = await addSubsidyLevel(params)
        if(res.state === 0) {
          this.$message.success(res.data)
          this.$emit('updateList')
          this.handleClose()
        }
      },
      /*编辑*/
      async updateSubsidyLevelPost() {
        const params = JSON.parse(JSON.stringify(this.ruleForm))
        params.age = params.age.join(',')
        params.id = this.rowItem.id
        const res = await updateSubsidyLevel(params)
        if(res.state === 0) {
          this.$message.success(res.data)
          this.$emit('updateList')
          this.handleClose()
        }
      },
      handleClose() {
        this.$emit('update:dialogVisible', false)
        this.$refs['ruleForm'].resetFields()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w200 {
    width: 300px;
  }
  .yuan {
    position: relative;
  }
  .yuan:after {
    content: '元';
    position: absolute;
    right: -20px;
    top: 0px;
  }
</style>
